
import Avatar from "./avatar";
import withAuth from "./data/withAuth";
import withCommunity from "./data/withCommunity";
import { splitLines } from '../lib/utils/userText';
import { getTimeAgo } from "../lib/utils/misc";
import GradientMask from "./ui/gradientMask";
import Button from "./ui/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarOutline, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarFilled, faThumbTack, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { classnames } from 'tailwindcss-classnames';
import PostVotePanel from "./postVotePanel";
import DomPurify from 'dompurify';
import getEnv from "../lib/getEnv";
import ImageDialog from './imageDialog'
import { useState } from "react";
import Dropdown from "./ui/dropdown";
import DropdownItem from "./ui/dropdownitem";
import Linkify from 'react-linkify';

function FeedPost(props) {
  const {
    post,
    community,
    onUpvote = (() => {}),
    onCommentClicked = (() => {}),
    onPin = (() => {}),
    gradientMask = false,
    showMenuButton = true,
  } = props;

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageDialogUrl, setImageDialogUrl] = useState(null);

  const { roles } = community;

  if (!roles) return '';

  let postContent;

  if (post && post.content && typeof window !== 'undefined') {
    postContent = splitLines(
      post.content,
      line => (
        <p className="last:mb-0 mb-2 text-base break-words leading-5 min-w-0">
          <Linkify
            properties={{
              target: '_blank',
              className: 'underline',
            }}
          >
            {DomPurify.sanitize(line)}
          </Linkify>
        </p>
      )
    );
  }

  return (
    <div className="w-full">
      {
        post.pinned && (
          <div className="w-full text-sm ml-1 mb-3 text-slate-500">
            <FontAwesomeIcon icon={faThumbTack} className="w-3 h-3" /> Pinned post
          </div>
        )
      }
      <div className={
        classnames(
          "flex flex-row gap-3 transition-opacity duration-100 w-full",
          post.optimistic && 'opacity-50 pointer-events-none',
        )
      }>

        <ImageDialog
          url={imageDialogUrl}
          open={imageDialogOpen}
          onClose={() => {
            setImageDialogOpen(false);
            setTimeout(() => {
              setImageDialogUrl(null);
            }, 300);
          }}
        />

        <div>
          <Avatar user={post.user} width={30} />
        </div>
        <div className="break-word min-w-0 grow">
          <div className="flex flex-row">
            <h4 className="text-sm mb-2 text-slate-600 grow">
              <b>@{post.user.username}</b> posted {getTimeAgo(post.timestamp)}
            </h4>
            {
              showMenuButton && community.roles && community.roles.includes('admin') && (
                <div className="-mt-2">
                  <Dropdown
                    placement="bottom-end"
                    button={
                      (props) => {
                        return <Button
                          size="base"
                          variant="transparent"
                          leftIcon={faEllipsis}
                        >
                        </Button>
                      }
                    }
                  >
                    <div className="py-1">
                      <DropdownItem
                        icon={faThumbTack}
                        onClick={() => { onPin(post.id, !post.pinned) }}
                      >
                        {post.pinned ? 'Unpin' : 'Pin'} post
                      </DropdownItem>
                    </div>
                  </Dropdown>
                </div>
              )
            }
          </div>

          <p className="break-words font-medium text-md last:mb-0 mb-1 text-slate-900">{post.title}</p>

          {
            gradientMask
              ? (
                <GradientMask showHeight={150}>
                  {postContent}
                </GradientMask>
              )
              : postContent
          }

          {
            post.images && (
              <div className="w-full h-60 relative mt-2 rounded-lg">
                <img
                  className="absolute h-full w-full object-cover hover:brightness-90"
                  src={`https://${getEnv() === 'prod' ? 'prod' : 'dev'}.cometuploads.com/${post.images[0].path}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setImageDialogOpen(true);
                    setImageDialogUrl(`https://${getEnv() === 'prod' ? 'prod' : 'dev'}.cometuploads.com/${post.images[0].path}`);
                  }}
                />
              </div>
            )
          }

          {
            (post.type === 'vote' && post.voting.choices) && (
              <PostVotePanel
                post={post}
              />
            )
          }

          <div className="w-full flex flex-row mt-2 text-base gap-5">
            <button
              className={classnames(
                "text-slate-700 hover:text-slate-400 font-medium transition-all flex flex-row"
              )}
              onClick={onCommentClicked ? ((e) => { e.stopPropagation(); onCommentClicked(); }) : (() => {})}
            >
              <FontAwesomeIcon icon={faCommentDots} className="mr-2 w-4 h-4 mt-0.5" />
              {post.numComments || '0'}
            </button>
            <button
              onClick={(e) => { e.stopPropagation(); onUpvote(post.id) }}
              className={classnames(
                'font-medium text-slate-700 transition-all disabled:cursor-not-allowed flex flex-row ',
                { 'text-yellow-500': post.myUpvote === 1 },
                { 'hover:text-yellow-500': roles.includes('upvotePosts') },
              )}
              disabled={!roles.includes('upvotePosts')}
            >
              <FontAwesomeIcon icon={post.myUpvote === 1 ? faStarFilled : faStarOutline} className="mr-1 w-4 h-4 mt-0.5" />
              {post.upvotes || '0'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(withCommunity(FeedPost), { authRequired: false });
