
import withAuth from "./data/withAuth";

function PostVotePanel(props) {
  const {
    post,
  } = props;

  return (
    <div className="w-full flex flex-col mb-3.5 mt-1.5 gap-2">
      {
        post.voting.choices.map(choice => (
          <div className="w-full">
            a
          </div>
        ))
      }
    </div>
  );
};

export default withAuth(PostVotePanel, { authRequired: false });
