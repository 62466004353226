
import React, { useEffect, useState } from 'react';
import classnames from 'tailwindcss-classnames';
import Button from './ui/button';
import TextField from './ui/textfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faX, faXmark, faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-textarea-autosize';

// const postTypes = [
//   {
//     id: 'post',
//     title: 'Post',
//     description: 'Normal post, for announcements and discussions.',
//   },
//   // {
//   //   id: 'vote',
//   //   title: 'Vote',
//   //   description: 'Allow community members to vote on a topic.',
//   // },
//   // {
//   //   id: 'challenge',
//   //   title: 'Challenge',
//   //   description: 'Offer a bounty for completing a task.',
//   // },
// ];

function Composer(props) {
  const {
    // variant = 'filled',
    size = 'base',
    loading = false,
    className,
    canCancel = false,
    hasTitle = false,

    value = {},
    onChange = (() => {}),
    onSubmit = (() => {}),
    onCancel = (() => {}),

    ...rest
  } = props;

  const [title, setTitle] = useState(value.title || '');
  const [content, setContent] = useState(value.content || '');
  const [file, setFile] = useState(value.file || null);
  const [type, setType] = useState(value.type || 'post');

  // onChange({
  //   title,
  //   content,
  //   file,
  //   type,
  // });

  useEffect(() => {
    // console.log(file);
    onChange({
      title,
      content,
      file,
      type,
    });
  }, [title, content, file, type]);

  return (
    <div className='relative'>
      <div className="rounded-2xl overflow-hidden bg-slate-100 relative p-1">
        {
          hasTitle && (
            <>
              <label for="title" class="sr-only bg-transparent">Title</label>
              <input
                value={title}
                onChange={(e) => { setTitle(e.target.value) }}
                type="text"
                name="title"
                id="title"
                className="bg-transparent block w-full border-0 pt-3 pb-0 text-lg font-medium placeholder-slate-500 focus:ring-0"
                placeholder="Title"
                autoFocus
              />
            </>
          )
        }

        <div className='mt-2'>
          <label for="description" class="sr-only bg-transparent">Description</label>
          <TextareaAutosize
            value={content}
            onChange={(e) => { setContent(e.target.value); }}
            minRows={3}
            maxRows={25}
            name="description"
            id="description"
            className="block leading-6 bg-transparent w-full border-0 py-0 resize-none placeholder-slate-500 focus:ring-0 text-md"
            placeholder="Write here..."
            autoFocus={!hasTitle}
          ></TextareaAutosize>
        </div>

        {
          file && (
            <div className='w-full px-3 mt-4'>
              <div className='relative h-20 w-20'>
                <div className='relative h-full w-full border-slate-600 border-2 rounded-lg overflow-hidden'>
                  <img
                    className='absolute h-full w-full object-cover'
                    src={URL.createObjectURL(file)}
                  />
                </div>
                <div className='absolute top-0 right-0 -mr-1.5 -mt-1.5'>
                  <Button
                    className='w-5 h-5 rounded-full bg-slate-600 text-white'
                    leftIcon={faXmark}
                    onClick={() => { setFile(null) }}
                  ></Button>
                </div>
              </div>
            </div>
          )
        }

        {/* <!-- Spacer element to match the height of the toolbar --> */}
        <div aria-hidden="true">
          <div class="py-2">
            {/* <div class="h-9"></div> */}
          </div>
          <div class="h-px"></div>
          <div class="py-2">
            <div class="py-px">
              <div class="h-9"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 inset-x-px">
        {/* <!-- Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. --> */}
        <div className="border-t border-slate-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
          <div className="flex">
            <label className="-ml-2 -my-2 rounded-full px-2 py-2 inline-flex items-center text-left text-slate-400 cursor-pointer hover:text-slate-600">
              <input
                type="file"
                className='hidden'
                onChange={(e) => { if (e.target.files && e.target.files[0]) setFile(e.target.files[0]); }}
                accept="image/png,image/jpeg,image/jpg"
              />

              <FontAwesomeIcon icon={faImage} className="w-5 h-5" />
            </label>

            {/* <button
              className="-my-2 rounded-full px-2 py-2 inline-flex items-center text-left text-slate-400 cursor-pointer hover:text-slate-600"
            >
              <FontAwesomeIcon icon={faSquarePollHorizontal} className="w-5 h-5" />
            </button> */}
          </div>
          <div className='grow' />
          {
            canCancel && (
              <Button
                onClick={onCancel}
                variant="transparent"
              >
                Cancel
              </Button>
            )
          }
          <div class="flex-shrink-0">
            <Button
              onClick={onSubmit}
              loading={loading}
              disabled={hasTitle && (value.title || '').trim().length === 0}
            >
              Post
            </Button>
            {/* <button type="submit" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Composer;
