
import React, { useEffect, useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function GradientMask(props) {
  const ref = React.createRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const calcSize = () => {
    if (ref && ref.current) {
      const { current } = ref;
      const width = current.offsetWidth;
      const height = current.offsetHeight;
      return { width: Math.round(width), height: Math.round(height) };
    }

    return {
      width: 0,
      height: 0,
    }
  }

  useEffect(() => {
    if (ref.current) {
      const { width, height } = calcSize();
      const { width: oldWidth, height: oldHeight } = dimensions;

      if (width !== oldWidth || height !== oldHeight) {
        setDimensions({ width, height });
      }
    }
  }, [ref]);

  useEffect(() => {
    setTimeout(() => {
      setDimensions(calcSize());
    }, 2000);
  }, []);

  const { showHeight, children } = props;

  const style = {};

  style.maxHeight = showHeight;
  style.position = 'relative';
  style.overflowY = 'hidden';

  return (
    <>
      <div style={style} className={classNames(dimensions.height > showHeight && 'gradient-mask-b-50')}>
        <div ref={ref}>
          { children }
        </div>
      </div>
    </>
  );
}
